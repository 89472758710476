import React from 'react';
import Dialog from './Dialog.js';
import Spinner from './Spinner.js';
import {doFetch} from '../helpers/fetch.js';
import makeSnack from '../elements/Snack.js';
import autobind from 'auto-bind';

export default makeSnack(class Form extends React.Component
{
  constructor (props)
  {
    super(props);
    autobind(this);
    this.state = {
      apiPending: false,
      elements: this.translateElements(props.elements),
      image_type: props.image_type,
      isConfirming: false,
    };
  }

  close ()
  {
    if (this.props.onClose)
    {
      this.props.onClose();
    }
  }

  translateElements (elements)
  {
    if (!elements)
    {
      return (null);
    }
    return (elements.map(element => {
      element.value = (element.value !== undefined) ? element.value : ((element.type === 'text' || element.type === 'password') ? '' : null);
      if (element.type === 'file')
      {
        element.formData = null;
      }
      return (element);
    }));
  }

  setUploadImage (name, event)
  {
     const file = event.target.files[0];
     const formData = new FormData();
     formData.append(name, file);
     this.setState ({
        elements: this.state.elements.map(element => element.name !== name ? element : {
          ...element,
          formData,
        }),
     });
  }

  async uploadImage (fetchSpec, formData)
  {
    let res = await doFetch(fetchSpec.method, fetchSpec.url, formData, false);
    return (res);
  }

  async submit ()
  {
    this.setState({
      apiPending: !this.state.apiPending,
    });
    let fetchSpecs = (typeof this.props.fetch === 'function') ? this.props.fetch() : this.props.fetch;
    try
    {
      let body = fetchSpecs.body ? fetchSpecs.body : {};
      this.state.elements.forEach(element => {
        if (element.value !== null && element.type !== 'custom')
        {
          body[element.name] = element.value;
        }
      });
      let res = null;
      if (fetchSpecs.method && fetchSpecs.url)
      {
        res = await doFetch(fetchSpecs.method, fetchSpecs.url, body);
      }
      for (let i=0; i<this.state.elements.length; i++)
      {
        let element = this.state.elements[i];
        if (element.type === 'file' && element.formData)
        {
          await this.uploadImage (element.fetch, element.formData);
        }
      }
      if (this.props.confirmDialog)
      {
        let func = () => this.onSuccess(fetchSpecs.onSuccess, res, this.state.elements);
        this.setState({
          confirmDialog: React.cloneElement(this.props.confirmDialog, {
            onConfirm: func,
            onClose: func,
          }),
        });
      }
      else
      {
        this.onSuccess(fetchSpecs.onSuccess, res, this.state.elements);
      }
    }
    catch (error)
    {
      this.props.openSnackbar(<span>{ JSON.stringify(error) }</span>);
      this.setState({
        apiPending: false,
      });
    }
  }

  onSuccess (func, res, elements)
  {
    if (func)
    {
      func(res, elements);
    }
  }

  onChange (name, value)
  {
    let elements = this.state.elements.map(element => element.name !== name ? element : {
      ...element,
      value,
    });
    this.setState({
      elements,
    });
  }

  renderElement (element, i)
  {
    if (element.type === 'text')
    {
      return (
        <div className="m-3" key={i}>
          { element.label }
          <br />
          <input type="text" value={element.value} onChange={e => this.onChange(element.name, e.target.value)} size="50" className="border border-green-300 bg-green-100 w-full" />
        </div>
      );
    }
    else if (element.type === 'password')
    {
      return (
        <div className="m-3" key={i}>
          { element.label }
          <br />
          <input type="password" value={element.value} onChange={e => this.onChange(element.name, e.target.value)} size="50" className="border border-green-300 bg-green-100 w-full" />
        </div>
      );
    }
    else if (element.type === 'select')
    {
      return (
        <div className="m-3" key={i}>
          { element.label }
          <br />
          <select className="border border-green-300 bg-green-100 w-full" value={element.value} onChange={e => this.onChange(element.name, e.target.value)} >
            { element.options.map(option =>
              <option> {option.name} </option>
            )}
          </select>
        </div>
      );
    }
    else if (element.type === 'checkbox')
    {
      return (
        <div className="m-3" key={i}>
          <input type="checkbox" checked={element.value} onChange={e => this.onChange(element.name, !element.value)} />
          &nbsp;
          { element.label }
        </div>
      );
    }
    else if (element.type === 'file')
    {
      return (
        <div className="m-3" key={i}>
          { element.label }
          <div className="border border-green-300 bg-green-100 w-full" >
            <input type="file" onChange={e => this.setUploadImage(element.name, e)} />
          </div>
        </div>
      );
    }
    else if (element.type === 'submit')
    {
      return (
        <div key={i} onClick={this.submit} className="text-center border border-cyan-600 rounded-md p-3 bg-cyan-500 hover:bg-cyan-700 text-white uppercase m-5 cursor-pointer">
          { this.state.apiPending ? <Spinner /> : element.label }
        </div>
      );
    }
    else if (element.type === 'link')
    {
      return (
        <div key={i} className="text-center m-3" onClick={element.func}>
          { element.label }
        </div>
      );
    }
    else if (element.type === 'custom')
    {
      return (element.body);
    }
    else
    {
      // some error handling here
    }
  }

  render ()
  {
    const notInFooter = (e) => !e.footer;
    const inFooter = (e) => e.footer;
    return (
      <React.Fragment>
        <div className="text-left">
          { this.props.disabled ? null :
            <Dialog title={this.props.title} description={this.props.description} onClose={this.close}>
              { this.state.elements && this.state.elements.filter(notInFooter).map(this.renderElement) }
              { this.renderElement({type:'submit', label:this.props.submitLabel}) }
              { this.state.elements && this.state.elements.filter(inFooter).map(this.renderElement) }
            </Dialog>
          }
        </div>
        { this.state.confirmDialog }
      </React.Fragment>
    );
  }
})

