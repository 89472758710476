import envConfig from '../env.js';

const config = {
  local: {
      api: {
        path: 'http://localhost:443',
      },
      client: {
        path: 'http://localhost',
      },
      staticServer: {
        path: 'https://api-static.cryptoconquerors.com',
      },
  },
  test: {
    api: {
      path: 'http://localhost:443',
    },
    client: {
      path: 'http://localhost',
    },
    staticServer: {
      path: 'https://api-static.cryptoconquerors.com',
    },
  },
  prod: {
    api: {
      path: "https://api.cryptoconquerors.com",
    },
    client: {
      path: "https://api.cryptoconquerors.com",
    },
    staticServer: {
      path: 'https://api-static.cryptoconquerors.com',
    },
  },
};

const get = () =>
{

    return (config[envConfig.env]);




};

export default get;

