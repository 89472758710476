import React from 'react';
import PropTypes from 'prop-types';
//import ConfirmDialog from '../elements/ConfirmDialog.js';//requireCycle
import autobind from 'auto-bind';







































class Button extends React.Component
{
  constructor (props)
  {
    super (props);
    autobind(this);
    this.state = {
      //showingConfirm: false,//requireCycle
    };
  }

  async onClick ()
  {
/* //requireCycle
    if (this.props.confirm)
    {
      this.setState({
        showingConfirm: true,
      });
    }
    else
    {
*/
      if (this.props.onClick)
      {
        this.props.onClick();
      }
/* //requireCycle
    }
*/
  }

/*
  onConfirm ()
  {
    this.setState({
      showingConfirm: false,
    });
    if (this.props.onClick)
    {
      this.props.onClick();
    }
  }

  onCancel ()
  {
    this.setState({
      showingConfirm: false,
    });
  }
*/

  getStyle ()
  {
    let className = '';

    // handle colors
    let color = this.props.color ? this.props.color : 'white';
    let colorMost = (color === 'white') ? color : `${color}-700`;
    let colorMore = (color === 'white') ? color : `${color}-600`;
    let colorLess = (color === 'white') ? color : `${color}-500`;
    let colorText = (color === 'white') ? 'cyan-600' : 'white';

    // handle text
    let casing = this.props.tiny ? 'lowercase' : 'uppercase';
    colorText = 'white';
    className += ` text-center ${casing} text-${colorText}`;

    // handle border
    let borderColor = (color === 'white') ? 'cyan-600' : colorMore;
    className += ` border border-solid rounded-md border-${borderColor}`;

    // handle background
    let hoverBgColor = (color === 'white') ? 'yellow-100' : colorMost;
    hoverBgColor = 'cyan-700';
    let bgColor = colorLess;
    bgColor = borderColor;
    className += ` bg-${bgColor} hover:bg-${hoverBgColor}`;

    // handle sizing
    let p = this.props.tiny ? 'p-1' : 'p-3';
    className += ` w-3/6 ${p}`;
    if (this.props.full)
    {
      className += ' my-2 w-full';
    }

    // handle cursor
    className += ' cursor-pointer';

    // handle inline
    if (this.props.tiny)
    {
      className += ' inline';
    }

    return (className);
  }

  render ()
  {
    if (this.props.disabled) return (null);
    let className = this.getStyle();

      if (this.props.sideMenu)
      {
        return (
          <React.Fragment>
            <button onClick={this.props.onClick} className="block px-4 py-2 mt-2 text-sm font-semibold text-gray-900 bg-transparent rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">{this.props.label}</button>
          </React.Fragment>
        );
      }
      return (
        <React.Fragment>
          { this.props.full &&
            <div onClick={this.onClick} className={className}>
              <b><nobr>{this.props.label}</nobr></b>
            </div>
          }
          { !this.props.full &&
            <span onClick={this.onClick} className={className}>
              <b><nobr>{this.props.label}</nobr></b>
            </span>
          }
          { /* //requireCycle
              !this.state.showingConfirm ? null :
              <ConfirmDialog label={this.props.confirm} onConfirm={this.onConfirm} onCancel={this.onCancel} onClose={this.onCancel} />
          */ }
        </React.Fragment>
      );





















  }
}

Button.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Button;

