import { withSnackbar } from 'react-simple-snackbar';

let snackStyle = {
  position: 'bottom-right',
  style: {
    position: 'relative',
    zIndex: 10000,
    backgroundColor: 'midnightblue',
    color: 'lightblue',
    fontSize: '18px',
    textAlign: 'center',
  },
  closeStyle: {
    color: 'white',
    fontSize: '16px',
  },
};

const makeSnack = (obj) =>
{
  return (withSnackbar(obj, snackStyle));
};

export default makeSnack;

